import React from "react";
import footerLogo from "../../assets/Demo/Footer-Logo.svg";
import Divider from "../../components/divider";
import Facebook from "../../assets/Icons/social_icons/facebook.svg";
import Instagram from "../../assets/Icons/social_icons/instagram.svg";
import Pinterest from "../../assets/Icons/social_icons/Pinterest.png";
import Youtube from "../../assets/Icons/social_icons/yt.png";

const Footer = () => {
  return (
    <footer className="container" id="footer">
      <Divider height="60px" />
      {/* Website Links */}
      <ul className="footer-menu font-medium text-center m-auto xl:max-w-[70%]">
        <li className="lg:inline-block mx-8 my-6">
          <a href="/">Home</a>
        </li>
        <li className="lg:inline-block mx-8 my-8">
          <a href="/recycle">Recycle</a>
        </li>
        <li className="lg:inline-block mx-8 my-8">
          <a href="/collections/all">The Shop</a>
        </li>
        <li className="lg:inline-block mx-8 my-8">
          <a href="/contact-us">Contact Us</a>
        </li>
        <li className="lg:inline-block mx-8 my-8">
          <a href="/terms-conditions">Terms & Conditions</a>
        </li>
        <li className="lg:inline-block mx-8 my-8">
          <a href="/privacy-policy">Privacy Policy</a>
        </li>
        <li className="lg:inline-block mx-8 my-8">
          <a href="/return-policy">Return Policy</a>
        </li>
        <li className="lg:inline-block mx-8 my-8">
          <a href="/shipping-policy">Shipping Policy</a>
        </li>
        <li className="lg:inline-block mx-8 my-8">
          <a href="/cookies-policy">Cookies Policy</a>
        </li>
        <li className="lg:inline-block mx-8 my-8">
          <a
            href="https://hayd.shiprocket.co/"
            target="_blank"
            rel="noreferrer"
          >
            Track Order
          </a>
        </li>
      </ul>

      <Divider height="60px" />

      <div className="socials flex justify-center gap-8">
        <a href="https://www.facebook.com/hayd.brand" target="_blank">
          <img src={Facebook} />
        </a>
        <a href="https://www.instagram.com/hayd.india/" target="_blank">
          <img src={Instagram} />
        </a>
        <a href="https://in.pinterest.com/haydofficial/" target="_blank">
          <img src={Pinterest} style={{ width: "28px", height: "28px" }} />
        </a>
        <a href="https://www.youtube.com/@haydofficial" target="_blank">
          <img src={Youtube} style={{ width: "28px", height: "28px" }} />
        </a>
      </div>

      <Divider height="45px" />

      {/* Text Line */}
      <div className="text-center text-black-300 text-2xl opacity-[28%]">
        <h2 className="font-normal">We stay true to the environment</h2>
      </div>
      <Divider height="40px" />

      {/* Footer Logo */}
      <div className="flex justify-center">
        <a href="/">
          <img src={footerLogo} alt="" className="w-3/4 m-auto" />
        </a>
      </div>
      <Divider height="50px" />
    </footer>
  );
};

export default Footer;
