import React from "react";
import { NavLink } from "react-router-dom";
import axios from "../api/axios";
import CloseIconFilled from "../assets/Icons/close_icon_filled.svg";
import DownArrow from "../assets/Icons/down_arrow.svg";
import { BASE_URL } from "../Constant";
import { getCart } from "../functions/getCart";
import useCart from "../hooks/useCart";
import Divider from "./divider";

function CartItems({ items, showSelector, canRemove = true }) {
  const { cartItems, setCartItems } = useCart();

  const updateCartItem = (id, qty) => {
    axios
      .post("/cart/update", { id: id, quantity: parseInt(qty) })
      .then(async (res) => {
        setCartItems(await getCart());
        if (res.data?.coupon_error_message) {
          document.getElementById("coupon-error-message").innerHTML =
            "Coupon not applicable on current order";
        } else {
          document.getElementById("coupon-error-message").innerHTML = "";
        }
      })
      .catch((error) => {
        // console.log(error);
      });
  };

  return (
    <div className="cart-item flex items-start justify-between gap-5 py-3 xl:py-4">
      <div className={`cart-image ${showSelector ? "large-image" : ""}`}>
        <img src={`${BASE_URL}${items?.media_url}`} />
      </div>
      <div className="cart-content w-full">
        <div className="text-left">
          <h4>
            <NavLink to={`/products/${items?.slug}`}>{items?.title}</NavLink>
          </h4>
          <div className="price flex items-center gap-3">
            {parseInt(items?.sale_price) > 0 ? (
              <>
                <h4 className="sale">
                  <span className="currency-symbol">₹</span>
                  {items?.sale_price}
                </h4>
                <h4 className="regular">
                  <span className="currency-symbol">₹</span>
                  {items?.regular_price}
                </h4>
              </>
            ) : (
              <>
                <h4 className="sale">
                  <span className="currency-symbol">₹</span>
                  {items?.regular_price}
                </h4>
              </>
            )}
          </div>
          <Divider height="10px" />
          <div className="product-meta">
            {items?.variant_title && (
              <>
                <div className="flex gap-2">
                  <div className="flex justify-between w-[50px]">
                    <p className="section-heading meta-title ">Color</p>
                    <p className="section-heading seperator">:</p>
                  </div>
                  <p className="section-heading meta-value">
                    {items?.variant_options[0]}
                  </p>
                </div>
                <div className="flex gap-2">
                  <div className="flex justify-between w-[50px]">
                    <p className="section-heading meta-title ">Size</p>
                    <p className="section-heading seperator">:</p>
                  </div>
                  <p className="section-heading meta-value">
                    {items?.variant_options[1]}
                  </p>
                </div>
              </>
            )}
            <div className="flex items-center gap-2">
              {showSelector ? (
                <div className="flex gap-2">
                  <div className="relative select-container">
                    <select
                      className="quantity-selector section-heading"
                      defaultValue={items?.quantity}
                      onChange={(e) =>
                        updateCartItem(items.cart_id, e.target.value)
                      }
                    >
                      {Array(items?.max_qty || 1)
                        .fill()
                        .map((value, index) => {
                          return (
                            <option value={index + 1} key={index}>
                              {index + 1}
                            </option>
                          );
                        })}
                    </select>
                    <img src={DownArrow} />
                  </div>
                </div>
              ) : (
                <>
                  <div className="flex justify-between w-[50px]">
                    <p className="section-heading meta-title">Qty</p>
                    <p className="section-heading seperator">:</p>
                  </div>
                  <p className="section-heading meta-value">
                    {items?.quantity}
                  </p>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
      {canRemove && (
        <div
          className="remove-item cursor-pointer"
          title="Remove item"
          onClick={() => updateCartItem(items.cart_id, 0)}
        >
          <img src={CloseIconFilled} className="w-12" />
        </div>
      )}
    </div>
  );
}

export default CartItems;
