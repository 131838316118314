import React, { useEffect, useState, useRef } from "react";
import Modal from "../components/modal";
import RightArrow from "../assets/Icons/rightArrow.svg";
import EditIcon from "../assets/Icons/edit-icon.svg";
import Resend from "../assets/Icons/resend.svg";
import ResendBlur from "../assets/Icons/resend-blur.svg";
import Divider from "./divider";
import axios from "../api/axios";
import useAuth from "../hooks/useAuth";
import useCart from "../hooks/useCart";
import { getCart } from "../functions/getCart";

function LoginModal({ isOpen, setOpen }) {
  const mobileNumberInputRef = useRef(null);
  const [inputDisabled, setInputDisabled] = useState(true);
  const [buttonDisabled, setButtonDisabled] = useState(true);
  const [disableNumber, setDisableNumber] = useState(false);
  const [otpCounter, setOtpCounter] = useState(false);
  const [countDown, setCountDown] = useState(0);
  const [errorMsg, setErrorMsg] = useState("");
  const [otpErrorMsg, setOtpErrorMsg] = useState("");
  const [userNumber, setUserNumber] = useState("");

  const { auth, setAuth } = useAuth();
  const { setCartItems } = useCart();

  useEffect(() => {
    setErrorMsg("");
  }, [userNumber]);

  useEffect(() => {
    changeNumber();
  }, [isOpen]);

  useEffect(() => {
    if (countDown > 0) {
      const timer = setInterval(() => {
        setCountDown(countDown - 1);
      }, 1000);

      return () => {
        clearInterval(timer);
      };
    } else {
      setOtpCounter(false);
    }
  }, [countDown]);

  function getCodeBoxElement(index) {
    return document.getElementById("otp" + index);
  }

  function onKeyUpEvent(index, event) {
    const eventCode = event.which || event.keyCode;
    if (getCodeBoxElement(index).value.length === 1) {
      if (index !== 6) {
        getCodeBoxElement(index + 1).focus();
      } else {
        getCodeBoxElement(index).blur();
      }
    }
    if (eventCode === 8 && index !== 1) {
      getCodeBoxElement(index - 1).focus();
    }

    checkOTPLength();
  }

  const checkOTPLength = () => {
    const otpInput = document.getElementsByClassName("otp-input");
    let otp = "";
    for (let i = 0; i < otpInput.length; i++) {
      otp += otpInput[i].value;
    }

    setButtonDisabled(otp.length == 6 ? false : true);
  };

  const verifyNumber = () => {
    if (userNumber.length == 10 && validateNumber(userNumber)) {
      sendOTP();
    } else {
      setErrorMsg("Please enter a valid phone number");
      setInputDisabled(true);
      clearInput();
    }
  };

  function validateNumber(input_str) {
    let reg = /^\(?(\d{3})\)?[- ]?(\d{3})[- ]?(\d{4})$/;
    return reg.test(input_str);
  }

  const sendOTP = () => {
    axios
      .post("/api/auth/user/generateOTP", { contact: userNumber })
      .then((res) => {
        // console.log(res.data);
        setInputDisabled(false);
        setOtpCounter(true);
        setCountDown(30);
        setErrorMsg("");
        setDisableNumber(true);
        getCodeBoxElement(1).focus();
      })
      .catch((error) => {
        // console.log(error);
        if (error.response?.data?.isError) {
          setErrorMsg(error.response?.data?.message);
        }
      });
  };

  const clearInput = () => {
    setOtpErrorMsg("");
    setUserNumber("");
    const otpInput = document.getElementsByClassName("otp-input");
    for (let i = 0; i < otpInput.length; i++) {
      otpInput[i].value = "";
    }
  };

  const resendClearInputBoxes = () => {
    setOtpErrorMsg("");
    const otpInput = document.getElementsByClassName("otp-input");
    for (let i = 0; i < otpInput.length; i++) {
      otpInput[i].value = "";
    }
  };

  const resendOTP = () => {
    resendClearInputBoxes();
    setButtonDisabled(true);
    sendOTP();
  };

  const changeNumber = () => {
    setUserNumber("");
    if (mobileNumberInputRef.current) {
      mobileNumberInputRef.current.value = "";
    }
    clearInput();
    setInputDisabled(true);
    setButtonDisabled(true);
    setDisableNumber(false);
  };

  const verifyOTP = () => {
    const otpInput = document.getElementsByClassName("otp-input");

    let otp = "";

    for (let i = 0; i < otpInput.length; i++) {
      otp += otpInput[i].value;
    }

    axios
      .post("/api/auth/user/verify-otp", {
        otp: otp,
        contact: userNumber,
      })
      .then(async (res) => {
        setAuth({
          user: res.data.user,
        });
        // console.log(res.data.user.contact);

        spurIdentify(`91${res.data.user.contact}`);

        setCartItems(await getCart());

        setOpen(false);
      })
      .catch((error) => {
        // console.log(error);
        if (error.response?.data?.isError) {
          setOtpErrorMsg(error.response?.data?.message);
        }
      });
  };

  // SPUR IDENTITY
  const spurIdentify = (phone) => {
    if (window && window.spurPixel) {
      window.spurPixel.identifyUser(phone);
    } else {
      console.error("spurPixel is not available on the window object.");
    }
  };

  return (
    <>
      <Modal isOpen={isOpen} setOpen={setOpen} heading={"Login"}>
        <div className="label-input mb-12">
          <h6 className="section-heading mb-2">Phone number *</h6>
          <div className="flex justify-start">
            <input
              type={"tel"}
              placeholder="Enter phone number"
              className="w-full px-4 border-[1px] border-black-100 border-r-0"
              defaultValue={userNumber}
              onChange={(e) => setUserNumber(e.target.value)}
              disabled={disableNumber}
              maxLength={10}
              autoComplete={"off"}
              ref={mobileNumberInputRef}
            />
            <button
              className="bg-black-300 w-16 h-14 flex justify-center items-center"
              onClick={() => verifyNumber()}
              disabled={disableNumber}
            >
              <img src={RightArrow} className="w-4" />
            </button>
          </div>
          {errorMsg && <span className="error-message">{errorMsg}</span>}
        </div>

        <div className="flex items-center mb-2 gap-2">
          <h6 className="section-heading">Enter OTP</h6>
          {otpErrorMsg && <span className="error-message">{otpErrorMsg}</span>}
        </div>

        <div className="otp-input-container">
          <input
            type="tel"
            onKeyUp={(e) => onKeyUpEvent(1, e)}
            maxLength={1}
            className={`otp-input ${
              inputDisabled ? "pointer-events-none" : ""
            }`}
            id="otp1"
          />
          <input
            type="tel"
            maxLength={1}
            onKeyUp={(e) => onKeyUpEvent(2, e)}
            className={`otp-input ${
              inputDisabled ? "pointer-events-none" : ""
            }`}
            id="otp2"
          />
          <input
            type="tel"
            maxLength={1}
            onKeyUp={(e) => onKeyUpEvent(3, e)}
            className={`otp-input ${
              inputDisabled ? "pointer-events-none" : ""
            }`}
            id="otp3"
          />
          <input
            type="tel"
            maxLength={1}
            onKeyUp={(e) => onKeyUpEvent(4, e)}
            className={`otp-input ${
              inputDisabled ? "pointer-events-none" : ""
            }`}
            id="otp4"
          />
          <input
            type="tel"
            maxLength={1}
            onKeyUp={(e) => onKeyUpEvent(5, e)}
            className={`otp-input ${
              inputDisabled ? "pointer-events-none" : ""
            }`}
            id="otp5"
          />
          <input
            type="tel"
            maxLength={1}
            onKeyUp={(e) => onKeyUpEvent(6, e)}
            className={`otp-input ${
              inputDisabled ? "pointer-events-none" : ""
            }`}
            id="otp6"
          />
        </div>

        {!inputDisabled && (
          <>
            <Divider height={10} />
            <div className="flex justify-between items-center">
              <div
                className="flex items-center gap-2 cursor-pointer"
                onClick={() => changeNumber()}
              >
                <img src={EditIcon} className="w-3 h-3" />
                <h6>Change number</h6>
              </div>
              {/* set otp counter enable disable */}

              {otpCounter ? (
                <div className="flex items-center gap-2">
                  <img src={ResendBlur} className="w-3 h-3" />
                  <h6 className="section-heading">
                    Resend OTP In {countDown} Sec
                  </h6>
                </div>
              ) : (
                <div
                  className="flex items-center gap-2 cursor-pointer"
                  onClick={() => resendOTP()}
                >
                  <img src={Resend} className="w-3 h-3" />
                  <h6>Resend OTP</h6>
                </div>
              )}
            </div>
          </>
        )}

        <Divider height={30} />

        <button
          className="primary w-full"
          disabled={buttonDisabled}
          onClick={() => verifyOTP()}
        >
          Continue
        </button>
      </Modal>
    </>
  );
}

export default LoginModal;
