import { createContext, useState } from "react";

const CartContext = createContext({});

export const CartProvider = ({ children }) => {
  const [cartItems, setCartItems] = useState({});
  const [cartDrawer, setCartDrawer] = useState(false);

  return (
    <CartContext.Provider
      value={{ cartItems, setCartItems, cartDrawer, setCartDrawer }}
    >
      {children}
    </CartContext.Provider>
  );
};

export default CartContext;
