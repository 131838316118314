import React, { useState, useEffect } from "react";
import Close from "../assets/Icons/close_icon.svg";

function Modal(props) {
  const { isOpen, setOpen, footer, heading, fullModal, bottomModal } = props;

  return (
    isOpen && (
      <div
        className={`modal-container ${fullModal === true ? "full-modal" : ""}`}
      >
        <div
          className={`backdrop ${isOpen ? "open" : ""}`}
          onClick={() => setOpen(false)}
          style={bottomModal === true ? { zIndex: 1000000 } : null}
        ></div>
        <div
          className={`modal ${isOpen ? "open" : ""} ${
            bottomModal === true ? "modal-from-bottom" : ""
          }`}
        >
          {fullModal === true ? null : (
            <div
              className="cursor-pointer modal-close"
              onClick={() => setOpen(false)}
            >
              <img className=" w-[16px]" src={Close} />
            </div>
          )}
          <div className="modal-header">
            <h2>{heading}</h2>
          </div>
          <div className="modal-body">{props.children}</div>
          {footer && <div className="modal-footer">{footer}</div>}
        </div>
      </div>
    )
  );
}

export default Modal;
