import React from "react";
import Close from "../assets/Icons/close_icon_white.svg";
import Slider from "react-slick";
import { AnnouncementBarCarousel } from "../config/carouselSettings";

const TopNotification = () => {
  return (
    <div
      className="notification-bar top-0 sticky z-9999998"
      style={{ zIndex: 99999 }}
    >
      {/* <div className="absolute top-1 right-1 cursor-pointer p-2">
        <img src={Close} className="w-[10px]" />
      </div> */}
      {/* <div className="bg-black-300 p-2 z-[300] flex items-center justify-center">
        <a href="https://hayd.in/collections/women">
          <p className="text-white-200 text-center">
            10% OFF on all orders above ₹2200
          </p>
        </a>
      </div> */}
      <Slider {...AnnouncementBarCarousel}>
        <div className="bg-black-300 p-2 z-[300] flex items-center justify-center">
          <a href="https://hayd.in/collections/women">
            <p className="text-white-200 text-center">
              10% off on first purchase. Use code <b>"FRIENDS"</b>
            </p>
          </a>
        </div>
        <div className="bg-black-300 p-2 z-[300] flex items-center justify-center">
          <a href="https://hayd.in/collections/women">
            <p className="text-white-200 text-center">
              15% off on orders above ₹2000. Use code <b>"HAYD15"</b>
            </p>
          </a>
        </div>
      </Slider>
    </div>
  );
};

export default TopNotification;
