import React, { useEffect, useState } from "react";
import Modal from "../components/modal";
import axios from "../api/axios";
import useAuth from "../hooks/useAuth";
import { getUser } from "../functions/getUser";
import useCart from "../hooks/useCart";
import { getCart } from "../functions/getCart";

function AddAddressModal({ isOpen, setOpen }) {
  const [nameError, setNameError] = useState("");
  const [contactError, setContactError] = useState("");
  const [ad1Error, setad1Error] = useState("");
  const [ad2Error, setad2Error] = useState("");
  const [pincodeError, setPincodeError] = useState("");
  const [apiError, setApiError] = useState("");

  const { auth, setAuth } = useAuth();

  const { setCartItems } = useCart();

  const [address, setAddress] = useState({
    name: "",
    contact: "",
    address_line_1: "",
    address_line_2: "",
    pincode: "",
    city: "",
    state: "",
    country: "",
    address_type: "Home",
    is_default: true,
  });

  useEffect(() => {
    if (isOpen) {
      setAddress({
        ...address,
        name: auth?.user?.name,
        contact: auth?.user?.contact,
      });
    }
  }, [isOpen]);

  const handleChange = (key, value) => {
    setAddress({
      ...address,
      [key]: value,
    });
    if (key === "name") setNameError("");
    if (key === "contact") setContactError("");
    if (key === "address_line_1") setad1Error("");
    if (key === "address_line_2") setad2Error("");
  };

  const handlePincodeChange = (key, value) => {
    if (key === "pincode") setPincodeError("");
    setAddress({
      ...address,
      [key]: value,
    });

    if (value.trim().length == 6 && validateNumber(value, "pincode")) {
      axios
        .post(`/api/user/address/locality`, { pincode: value })
        .then((res) => {
          const city = res.data.locality;
          const state = res.data.state;
          const country = res.data.country;

          setAddress({
            ...address,
            pincode: value,
            city: city,
            state: state,
            country: country,
          });
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  const validateFields = () => {
    if (address.name.trim().length == 0) {
      setNameError("Please enter a valid name");
      return;
    }
    if (
      address.contact.toString().trim().length != 10 ||
      !validateNumber(address.contact)
    ) {
      setContactError("Please enter a valid number");
      return;
    }

    if (address.address_line_1.trim().length == 0) {
      setad1Error("Please enter a valid address");
      return;
    }

    if (address.address_line_2.trim().length == 0) {
      setad2Error("Please enter a valid value");
      return;
    }

    if (
      address.pincode.toString().trim().length != 6 ||
      !validateNumber(address.pincode, "pincode")
    ) {
      setPincodeError("Invalid pincode");
      return;
    }

    if (address.city.trim().length == 0) {
      setPincodeError("Pincode not serviceable");
      return;
    }

    saveAddress();
  };

  function validateNumber(input_str, type = "number") {
    let reg = "";
    if (type == "number") {
      reg = /^\(?(\d{3})\)?[- ]?(\d{3})[- ]?(\d{4})$/;
    } else {
      reg = /^[1-9][0-9]{5}$/;
    }
    return reg.test(input_str);
  }

  const saveAddress = () => {
    axios
      .post("/api/user/address", { address })
      .then(async (res) => {
        setAuth({
          user: await getUser(),
        });

        setCartItems(await getCart());

        setOpen(false);
      })
      .catch((error) => {
        setApiError(error?.response?.data?.message);
      });
  };

  return (
    <Modal isOpen={isOpen} setOpen={setOpen} heading={"Add new address"}>
      <div className="label-input mb-6">
        <h6 className="section-heading mb-2">Full name *</h6>

        <input
          type="text"
          name="name"
          className="w-full p-4 border-[1px] border-black-100"
          onChange={(e) => handleChange("name", e.target.value)}
          defaultValue={auth?.user?.name || address.name}
          maxLength={30}
          autoComplete="none"
        />

        {nameError && <span className="error-message">{nameError}</span>}
      </div>

      <div className="label-input mb-6">
        <h6 className="section-heading mb-2">Phone number *</h6>
        <input
          type="tel"
          name="contact"
          className="w-full p-4 border-[1px] border-black-100"
          onChange={(e) => handleChange("contact", e.target.value)}
          defaultValue={auth?.user?.contact || address.contact}
          maxLength={10}
          autoComplete="none"
        />
        {contactError && <span className="error-message">{contactError}</span>}
      </div>

      <div className="label-input mb-6">
        <h6 className="section-heading mb-2">
          Address (House No, Building, Street, Area) *
        </h6>
        <input
          type="text"
          name="address_line_1"
          className="w-full p-4 border-[1px] border-black-100"
          onChange={(e) => handleChange("address_line_1", e.target.value)}
          value={address.address_line_1}
          maxLength={60}
          autoComplete="none"
        />

        {ad1Error && <span className="error-message">{ad1Error}</span>}
      </div>

      <div className="label-input mb-6">
        <h6 className="section-heading mb-2">Locality, Town *</h6>
        <input
          type="text"
          name="address_line_2"
          className="w-full p-4 border-[1px] border-black-100"
          onChange={(e) => handleChange("address_line_2", e.target.value)}
          value={address.address_line_2}
          maxLength={60}
          autoComplete="none"
        />
        {ad2Error && <span className="error-message">{ad2Error}</span>}
      </div>

      <div className="flex justify-between gap-3">
        <div className="label-input mb-6 w-full">
          <h6 className="section-heading mb-2">Pincode *</h6>
          <input
            type="tel"
            name="pincode"
            className="w-full p-4 border-[1px] border-black-100"
            onChange={(e) => handlePincodeChange("pincode", e.target.value)}
            value={address.pincode}
            maxLength={6}
            autoComplete="none"
          />
          {pincodeError && (
            <span className="error-message">{pincodeError}</span>
          )}
        </div>

        <div className="label-input mb-6 w-full">
          <h6 className="section-heading mb-2">City *</h6>
          <input
            type={"text"}
            className="w-full p-4 border-[1px] border-black-100"
            name="city"
            onChange={(e) => handleChange("city", e.target.value)}
            value={address.city}
            readOnly
            disabled
          />
        </div>
      </div>
      <div className="flex justify-between gap-3">
        <div className="label-input mb-6 w-full">
          <h6 className="section-heading mb-2">State *</h6>
          <input
            type={"text"}
            className="w-full p-4 border-[1px] border-black-100"
            name="state"
            onChange={(e) => handleChange("state", e.target.value)}
            value={address.state}
            readOnly
            disabled
          />
        </div>

        <div className="label-input mb-6 w-full">
          <h6 className="section-heading mb-2">Country *</h6>
          <input
            type={"text"}
            className="w-full p-4 border-[1px] border-black-100"
            name="country"
            onChange={(e) => handleChange("country", e.target.value)}
            value={address.country}
            readOnly
            disabled
          />
        </div>
      </div>

      <h6 className="section-heading mb-2">Address type *</h6>
      <div className="flex justify-start gap-4 mb-6">
        <div className="flex items-center gap-2">
          <input
            type="radio"
            id="home"
            value={"Home"}
            name="address-type"
            defaultChecked={true}
            onChange={(e) => handleChange("address_type", e.target.value)}
          />
          <label htmlFor="home" className="cursor-pointer">
            Home
          </label>
        </div>
        <div className="flex items-center gap-2">
          <input
            type="radio"
            id="work"
            value={"Work"}
            name="address-type"
            onChange={(e) => handleChange("address_type", e.target.value)}
          />
          <label htmlFor="work" className="cursor-pointer">
            Work
          </label>
        </div>
      </div>

      <div className="flex items-center gap-2 mb-6">
        <input
          type="checkbox"
          id="default-add"
          name="default-address"
          defaultChecked={true}
          onChange={(e) => handleChange("is_default", e.target.checked)}
        />
        <label htmlFor="default-add" className="cursor-pointer">
          Make this my default address
        </label>
      </div>

      <button className="primary w-full" onClick={() => validateFields()}>
        Save address
      </button>

      {apiError && <span className="error-message">{apiError}</span>}
    </Modal>
  );
}

export default AddAddressModal;
