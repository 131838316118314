export const productCarousel = {
  dots: true,
  infinite: false,
  speed: 500,
  slidesToShow: 4,
  slidesToScroll: 4,
  customPaging: (i) => (
    <div
      style={{
        width: "10px",
        height: "10px",
        border: "1px #000 solid",
        borderRadius: "100%",
      }}
    ></div>
  ),
  responsive: [
    {
      breakpoint: 1140,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 3,
        infinite: true,
        dots: true,
      },
    },
    {
      breakpoint: 769,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2,
        initialSlide: 2,
      },
    },
    {
      breakpoint: 540,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2,
      },
    },
    {
      breakpoint: 360,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
  ],
};

export const bannerCarousel = {
  dots: true,
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  arrows: false,
  customPaging: () => (
    <div
      style={{
        width: "10px",
        height: "10px",
        border: "1px #fafafa solid",
        borderRadius: "100%",
      }}
    ></div>
  ),
};

export const productImageCarousel = {
  dots: true,
  infinite: false,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  arrows: false,

  customPaging: () => (
    <div
      style={{
        width: "10px",
        height: "10px",
        border: "1px #fafafa solid",
        borderRadius: "100%",
      }}
    ></div>
  ),
};

export const AnnouncementBarCarousel = {
  slidesToShow: 1,
  slidesToScroll: 1,
  autoplay: true,
  autoplaySpeed: 2000,
  dots: false,
  arrows: false,
};
