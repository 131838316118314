import React, { useEffect } from "react";
import Cart from "../assets/Icons/cart.svg";

function EmptyCart() {
  return (
    <div className="flex flex-col justify-center items-center gap-4 w-full h-full py-20">
      <img src={Cart} className="w-8 h-8" />
      <h5>Your cart is currently empty</h5>
      <a href={"/collections/all"} className="primary">
        Continue shopping
      </a>
    </div>
  );
}

export default EmptyCart;
