import React from "react";

const HomePage = React.lazy(() => import("../pages/home"));
const CollectionPage = React.lazy(() => import("../pages/collections"));
const ProductDetail = React.lazy(() => import("../pages/product_detail"));
const Cart = React.lazy(() => import("../pages/cart"));
const Checkout = React.lazy(() => import("../pages/checkout"));
const Profile = React.lazy(() => import("../pages/profile"));
const Thankyou = React.lazy(() => import("../pages/thankyou"));
const Maintenance = React.lazy(() => import("../pages/maintenance"));
const notFound = React.lazy(() => import("../pages/notFound"));
const Terms = React.lazy(() => import("../pages/policies/terms"));
const Privacy = React.lazy(() => import("../pages/policies/privacy"));
const Return = React.lazy(() => import("../pages/policies/return"));
const Cookies = React.lazy(() => import("../pages/policies/cookie"));
const Shipping = React.lazy(() => import("../pages/policies/shipping"));
const Contact = React.lazy(() => import("../pages/contact"));
const Recycle = React.lazy(() => import("../pages/recycle"));
const VerifyPayment = React.lazy(() => import("../pages/verifyPayment"));
const PaymentFailed = React.lazy(() => import("../pages/paymentFailed"));
const review = React.lazy(() => import("../pages/review"));

const routes = [
  { path: "/", component: HomePage },
  { path: "/collections/:handle", component: CollectionPage },
  {
    path: "/collections/:category/products/:handle",
    component: ProductDetail,
  },
  { path: "/products/:handle", component: ProductDetail },
  { path: "/cart", component: Cart },
  { path: "/checkout", component: Checkout },
  { path: "/profile/:tab", component: Profile },
  { path: "/profile/:tab/:id", component: Profile },
  { path: "/verify-payment/:order_id", component: VerifyPayment },
  { path: "/payment-failed/:order_id", component: PaymentFailed },
  { path: "/thank-you/:order_id", component: Thankyou },
  { path: "/maintenance", component: Maintenance },
  { path: "/page-not-found", component: notFound },
  { path: "/recycle", component: Recycle },
  { path: "/terms-conditions", component: Terms },
  { path: "/privacy-policy", component: Privacy },
  { path: "/return-policy", component: Return },
  { path: "/cookies-policy", component: Cookies },
  { path: "/shipping-policy", component: Shipping },
  { path: "/contact-us", component: Contact },
  { path: "/review/:order_id", component: review },
];

export default routes;
