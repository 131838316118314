import React from "react";
import useAuth from "../hooks/useAuth";
import DownArrow from "../assets/Icons/down_arrow.svg";
import useModal from "../hooks/useModal";

function MobileMenu({ menus, setMobileDrawer, openLoginModal }) {
  const toggleMenu = (index) => {
    const item = document.querySelectorAll(".mobile-menu .menu-item");
    if (index == null) {
      item[item.length - 1].classList.toggle("active");
    } else {
      item[index].classList.toggle("active");
    }
  };

  const { auth } = useAuth();
  const { setOpenLogin, setLogoutModal } = useModal();

  const openLogin = () => {
    setMobileDrawer(false);
    setOpenLogin(true);
  };

  return (
    <div className="mobile-menu">
      {menus.map((value, index) => {
        return (
          <li
            key={index}
            className="menu-item"
            onClick={() => toggleMenu(index)}
          >
            <div className="flex justify-between">
              {value.has_submenu === 1 ? (
                <>
                  <h2>{value.title}</h2>
                  <img src={DownArrow} className="w-4 accordion-arrow" />
                </>
              ) : (
                <>
                  <a href={value.url}>
                    <h2>{value.title}</h2>
                  </a>
                </>
              )}
            </div>
            {value.has_submenu === 1 ? (
              <div className="sub-menu">
                {value.submenu.map((data, index) => {
                  if (data !== null) {
                    return (
                      <ul key={index}>
                        <h4 className="py-4 section-heading">
                          {data[0]?.heading}
                        </h4>
                        {data.map((res, index2) => {
                          return (
                            <li key={index2} className="pb-3">
                              <p>
                                <a href={`${res.url}`}>{res.title}</a>
                              </p>
                            </li>
                          );
                        })}
                      </ul>
                    );
                  }
                })}
              </div>
            ) : (
              ""
            )}
          </li>
        );
      })}

      <li className="menu-item">
        <a href="/recycle">
          <h2>Recycle</h2>
        </a>
      </li>
      {auth?.user ? (
        <li className="menu-item" onClick={() => toggleMenu(null)}>
          <div className="flex justify-between">
            <h2>My account</h2>
            <img src={DownArrow} className="w-4 accordion-arrow" />
          </div>
          <div className="sub-menu">
            <ul className="py-4">
              <li className="pb-3">
                <p>
                  <a href={`/profile/account`}>Profile</a>
                </p>
              </li>
              <li className="pb-3">
                <p>
                  <a href={`/profile/orders`}>My orders</a>
                </p>
              </li>
              <li className="pb-3">
                <p>
                  <a href={`/profile/wallet`}>Wallet</a>
                </p>
              </li>
              <li className="pb-3">
                <p>
                  <a href={`/profile/wishlist`}>Wishlist</a>
                </p>
              </li>
              <li className="pb-3">
                <p>
                  <a href={`/profile/faqs`}>FAQ's</a>
                </p>
              </li>
              <li className="pb-3">
                <p>
                  <a href={`/profile/manage-addresses`}>Manage addresses</a>
                </p>
              </li>
              <li className="pb-3" onClick={() => setLogoutModal(true)}>
                Logout
              </li>
            </ul>
          </div>
        </li>
      ) : (
        <li className="menu-item" onClick={openLoginModal}>
          <h2>Login</h2>
        </li>
      )}
    </div>
  );
}

export default MobileMenu;
