import React, { useState, useEffect } from "react";
import Search from "../../assets/Icons/search.svg";
import Profile from "../../assets/Icons/profile.svg";
import Recycle from "../../assets/Icons/recycle.svg";
import Cart from "../../assets/Icons/cart.svg";
import headerLogo from "../../assets/Demo/Header-Logo.svg";
import menuMobile from "../../assets/Icons/menu-mobile.svg";
import MegaMenu from "../../menus/megaMenu";
import Drawer from "../../components/drawer";
import MobileMenu from "../../menus/mobileMenu";
import { useNavigate, NavLink, useLocation } from "react-router-dom";
import axios from "../../api/axios";
import CartItems from "../../components/cartItems";
import useCart from "../../hooks/useCart";
import LoginModal from "../../components/loginModal";
import useAuth from "../../hooks/useAuth";
import AddAddressModal from "../../components/addAddressModal";
import useModal from "../../hooks/useModal";
import { getUser } from "../../functions/getUser";
import { getCart } from "../../functions/getCart";
import EmptyCart from "../../components/emptyCart";
import Divider from "../../components/divider";
import { BASE_URL } from "../../Constant";
import LogoutModal from "../../components/logoutModal";

const Header = () => {
  const [menus, setMenus] = useState([]);
  const [mobileDrawer, setMobileDrawer] = useState(false);
  const [searchDrawer, setSearchDrawer] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [searchNotice, setSearchNotice] = useState("");
  const [searchResult, setSearchResult] = useState([]);
  const [isLoginOpen, setLoginOpen] = useState(false);

  const { cartItems, setCartItems, cartDrawer, setCartDrawer } = useCart();

  const location = useLocation();
  const navigate = useNavigate();

  const { auth, setAuth } = useAuth();

  const { addressModal, setAddressModal, logoutModal, setLogoutModal } =
    useModal();

  useEffect(() => {
    getMenus();
    getUserDetails();
  }, []);

  // FOR SEARCH QUERY
  useEffect(() => {
    const controller = new AbortController();

    if (searchQuery.trim() != "") {
      axios
        .get(`/search?query=${searchQuery}`, {
          signal: controller.signal,
        })
        .then((res) => {
          // console.log(res.data);
          setSearchNotice("");
          setSearchResult(res.data);
          if (res.data.length === 0) {
            setSearchNotice("No products found");
          }
        })
        .catch((error) => {
          // console.log(error);
          setSearchNotice("No products found");
        });
    } else {
      setSearchResult([]);
      setSearchNotice("");
    }

    return () => {
      controller.abort();
    };
  }, [searchQuery]);

  const getUserDetails = async () => {
    try {
      setCartItems(await getCart());
      setAuth({
        user: await getUser(),
      });
    } catch (error) {
      console.log("no token found");
    }
  };

  const getMenus = () => {
    axios
      .post("/api/getMenus")
      .then((response) => {
        setMenus(response.data);
      })
      .catch((error) => {
        // console.log(error);
      });
  };

  const openCart = () => {
    if (location.pathname === "/cart" || location.pathname === "/checkout") {
      navigate("/cart");
    } else {
      setCartDrawer(true);
    }
  };

  // open login modal in mobile view
  const openLoginModal = () => {
    setLoginOpen(true); // Open the LoginModal
    setMobileDrawer(false); // Close the mobile menu
  };

  return (
    <nav
      className="sticky main-header"
      style={{ zIndex: 1000, backgroundColor: "white" }}
    >
      <div id="header" className="text-black-300 text-base">
        <div className="container flex justify-between items-center ">
          {/* Mobile hamburger menu */}
          <div className="xl:hidden w-1/2">
            <img
              src={menuMobile}
              className="w-6 h-6"
              onClick={() => setMobileDrawer(true)}
            />
          </div>

          {/* Desktop Left menu */}
          <ul className="xl:flex lg:gap-12 xl:gap-8 xl:w-1/2 hidden">
            {menus.map((value, index) => {
              return (
                <li key={index} className="menu-item">
                  <a href={value.has_submenu === 1 ? "#" : value.url}>
                    {value.title}
                  </a>
                  {value.has_submenu === 1 ? (
                    <MegaMenu menus={value.submenu} title={value.title} />
                  ) : (
                    ""
                  )}
                </li>
              );
            })}
          </ul>

          {/* Center Logo */}
          <a href="/" className="logo">
            <img src={headerLogo} alt="" className="xl:w-3/4 m-auto" />
          </a>

          {/* Desktop Right menu */}
          <div className="xl:flex justify-end w-1/2">
            {/* Search, Profile, Recycle and cart menu */}
            <ul className="flex xl:gap-12 sm:gap-3 gap-2 items-center justify-end">
              <li className="menu-item" onClick={() => setSearchDrawer(true)}>
                <img src={Search} className="w-5 h-5" />
              </li>

              <li className="menu-item">
                {auth.user ? (
                  <NavLink to={"/profile/account"}>
                    <img src={Profile} className="w-5 h-5 hidden xl:block" />
                  </NavLink>
                ) : (
                  <img
                    src={Profile}
                    className="w-5 h-5 hidden xl:block"
                    onClick={() => setLoginOpen(true)}
                  />
                )}
              </li>

              <li className="menu-item">
                <NavLink to={"/recycle"}>
                  <img src={Recycle} className="w-5 h-5 hidden xl:block" />
                </NavLink>
              </li>

              <li className="menu-item" onClick={() => openCart()}>
                <div className="relative">
                  {cartItems && Object.keys(cartItems)?.length != 0 ? (
                    <span className="cart-count">{cartItems.item_count}</span>
                  ) : (
                    ""
                  )}
                  <img src={Cart} className="w-5 h-5" />
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>

      {/* MOBILE MENU */}
      <Drawer
        position={"left"}
        isOpen={mobileDrawer}
        setOpen={setMobileDrawer}
        heading={<img src={headerLogo} width="100px" />}
      >
        <MobileMenu
          menus={menus}
          setMobileDrawer={setMobileDrawer}
          openLoginModal={openLoginModal}
        />
      </Drawer>

      {/* SEARCH DRAWER */}
      <Drawer
        position={"right"}
        isOpen={searchDrawer}
        setOpen={setSearchDrawer}
        heading={"Search products"}
      >
        <div className="search-container w-full relative">
          <img
            src={Search}
            className="absolute top-[50%] left-4 translate-y-[-50%] opacity-50"
            width="18px"
          />
          <input
            className="p-4 w-full indent-7"
            placeholder="What are you looking for?"
            onChange={(e) => setSearchQuery(e.target.value)}
          />
        </div>

        <Divider height={20} />

        {searchResult?.length > 0 ? (
          <>
            {searchResult?.map((item, index) => {
              return (
                <React.Fragment key={index}>
                  <a
                    href={`/products/${item.slug}`}
                    className="flex gap-4 items-center"
                  >
                    <div className="search-image">
                      <img src={`${BASE_URL}${item.medias[0].url}`} />
                    </div>
                    <div>
                      <h4>{item.title}</h4>
                      <div className="price flex items-center gap-3">
                        {parseInt(item?.sale_price) > 0 ? (
                          <>
                            <h4 className="sale">
                              <span className="currency-symbol">₹</span>
                              {item?.sale_price}
                            </h4>
                            <h4 className="regular">
                              <span className="currency-symbol">₹</span>
                              {item?.regular_price}
                            </h4>
                          </>
                        ) : (
                          <>
                            <h4 className="sale">
                              <span className="currency-symbol">₹</span>
                              {item?.regular_price}
                            </h4>
                          </>
                        )}
                      </div>
                    </div>
                  </a>
                  <br />
                </React.Fragment>
              );
            })}
          </>
        ) : (
          <h5 className="p-6 text-center">{searchNotice}</h5>
        )}
      </Drawer>

      {/* CART DRAWER */}
      <Drawer
        position={"right"}
        isOpen={cartDrawer}
        setOpen={setCartDrawer}
        heading={"Cart"}
        footer={
          <>
            {cartItems?.items?.length > 0 ? (
              <>
                <a href={"/cart"} className="primary w-full">
                  View Cart
                </a>
                <a href={"/checkout"} className="outlined w-full">
                  Checkout
                </a>
              </>
            ) : (
              ""
            )}
          </>
        }
      >
        {cartItems?.items?.length > 0 ? (
          <>
            {cartItems?.items?.map((item, index) => {
              return (
                <React.Fragment key={index}>
                  <CartItems canRemove={true} items={item} />
                  {index < cartItems.items.length - 1 ? <hr /> : ""}
                </React.Fragment>
              );
            })}
          </>
        ) : (
          <EmptyCart />
        )}
      </Drawer>

      {/* LOGIN MODAL  */}
      <LoginModal isOpen={isLoginOpen} setOpen={setLoginOpen} />

      {/* ADD NEW ADDRESS */}
      <AddAddressModal isOpen={addressModal} setOpen={setAddressModal} />

      {/* LOGOUT MODAL */}
      <LogoutModal isOpen={logoutModal} setOpen={setLogoutModal} />
    </nav>
  );
};

export default Header;
